




































// Core
import { Component, Prop, Vue } from 'vue-property-decorator'

// Services
import http from '@/http'

// Interfaces
import { ElForm } from 'element-ui/types/form'
import { User } from '@/store/users/interfaces'

@Component({
  name: 'FormSupport',
})
export default class FromSupportComponent extends Vue {
  @Prop(Object) private user!: User

  private form = {
    email: this.user.email,
    message: '',
  }

  private rules: any = {
    email: [
      { required: true, message: 'Обязательное поле', trigger: 'blur' },
      { type: 'email', message: 'Неправильный e-mail', trigger: ['blur', 'change'] },
    ],
    message: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
  }

  private handleSend() {
    const form = this.$refs.form as ElForm

    form.validate(isValid => {
      if (isValid) {
        http.post('/support', this.form).then(() => {
          this.$notify({
            title: 'Выполнено',
            message: 'Сообщение отправлено',
            type: 'success',
          })
          this.$emit('close-dialog')
        })
        .catch(error => {
            this.$notify.error({
              title: 'Ошибка',
              message: error.response.data.message,
            })
          })
      }
    })
  }
}
